// This file renders the component to let user know about his profile being updated in his previous login and re-logged in.
import { IonAlert } from "@ionic/react";
import { PROFILE_UPDATE_LOCAL_STORAGE_ITEMS } from "@src/app/profile/profile/constants";
import { ActionType } from "@store/session";
import { Store } from "@store/store.model";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const EMAIL_MESSAGE =
  "Your email has been replaced by the new one you have just input. You now can use the new email to login to your account and receive messages";
const PHONE_MESSAGE =
  "Your phone has been replaced by the new one you have just input. You now can use the new phone number to login to your account and receive SMS notifications";

const ProfileUpdatePopup = () => {
  const popups = useSelector((state: Store) => state?.session?.popups);
  const { isAuthorized } = useSelector((state: Store) => state?.session);
  const { visible, updateType } = popups?.profileUpdatePopup ?? {};
  const dispatch = useDispatch();

  const onClick = async () => {
    dispatch({ type: ActionType.HIDE_PROFILE_UPDATE_POPUP });
  };

  const resetShowProfilePopupLocalStorage = useCallback(() => {
    localStorage.setItem(PROFILE_UPDATE_LOCAL_STORAGE_ITEMS.UPDATE_PROFILE_EMAIL_CHANGED, "");
    localStorage.setItem(
      PROFILE_UPDATE_LOCAL_STORAGE_ITEMS.UPDATE_PROFILE_PHONE_NUMBER_CHANGED,
      ""
    );
  }, []);

  useEffect(() => {
    if (isAuthorized) {
      if (localStorage.getItem(PROFILE_UPDATE_LOCAL_STORAGE_ITEMS.UPDATE_PROFILE_EMAIL_CHANGED)) {
        dispatch({
          type: ActionType.SHOW_PROFILE_UPDATE_POPUP,
          data: { updateType: "EMAIL" },
        });
        resetShowProfilePopupLocalStorage();
      } else if (
        localStorage.getItem(PROFILE_UPDATE_LOCAL_STORAGE_ITEMS.UPDATE_PROFILE_PHONE_NUMBER_CHANGED)
      ) {
        dispatch({
          type: ActionType.SHOW_PROFILE_UPDATE_POPUP,
          data: { updateType: "PHONE" },
        });
        resetShowProfilePopupLocalStorage();
      }
    }
  }, [isAuthorized, resetShowProfilePopupLocalStorage]);

  if (!visible || !updateType) {
    return null;
  }

  return (
    <IonAlert
      isOpen
      header={`You've updated your ${updateType === "EMAIL" ? "email" : "phone"}!`}
      message={updateType === "EMAIL" ? EMAIL_MESSAGE : PHONE_MESSAGE}
      cssClass="alert-popup-container"
      mode="ios"
      backdropDismiss
      onDidDismiss={onClick}
      buttons={[
        {
          text: "Got it!",
          role: "cancel",
          handler: onClick,
        },
      ]}
    />
  );
};

export { ProfileUpdatePopup };
